import React from "react";
import {EmbedProviderProps} from "./EmbedProviders";
import VideoWebEmbedView from "./VideoWebEmbedView";
import IframelyVideoWebEmbedView from "./IframelyVideoWebEmbedView";

export function YouTube(props: EmbedProviderProps) {
    if(props.oembed?.html?.startsWith('<iframe')) {
      return <VideoWebEmbedView {...props} />;
    } else {
      return <IframelyVideoWebEmbedView {...props} />;
    }
}
