import React from "react";
import {EmbedProviderProps} from "./EmbedProviders";

export function IframelyVideoWebEmbedView({oembed}: EmbedProviderProps) {
  return <div className={`tik4-we-iframely-video tik4-we-iframely-video--${oembed.provider_name?.toLowerCase()}`}>
    <div className="tik4-we-iframely-video__container" dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
  </div>
}

export default IframelyVideoWebEmbedView
