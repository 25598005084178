import React, {forwardRef} from 'react';
import {Icon, IconProps} from 'react-feather'

export const AI: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><clipPath id="ai-icon-clip-path"><path fill={color} fillRule="evenodd" d="m8.502 9.483 1.773 1.002-4.115 2.252-.024 6.5 4.143 2.342-1.703.921-4.04-2.283-.099-8.454 4.065-2.28Zm1.295 3.222-.065 4.615 5.737 3.27 4.143-2.341-.036 1.904-4.04 2.284-7.527-4.143.015-4.587 1.773-1.002ZM21.333 8.67 23 9.653v4.567l-7.428 4.31-4.05-2.306V14.22l4.05 2.363 5.761-3.23V8.67ZM8.428 5.47l4.05 2.306V9.78l-4.05-2.363-5.761 3.23v4.683L1 14.347V9.78l7.428-4.31Zm6.996-3.97 4.04 2.283.099 8.454-4.065 2.28-1.773-1.002 4.115-2.252.024-6.5-4.143-2.342 1.703-.921Zm-6.96.063 7.527 4.143-.015 4.587-1.773 1.002.065-4.615-5.737-3.27L4.388 5.75l.036-1.904 4.04-2.284Z"/></clipPath></svg>


  // spiral
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><defs><linearGradient id="ai-icon-gradient-01" x1="14.127%" x2="83.686%" y1="17.975%" y2="82.525%"><stop offset="0%" stopColor="#E01A4F"/><stop offset="100%" stopColor="#006194"/></linearGradient><linearGradient id="ai-icon-gradient-02" x1="14.127%" x2="83.686%" y1="17.975%" y2="82.525%"><stop offset="0%" stopColor="#E72D5F"/><stop offset="100%" stopColor="#0B9AE6"/></linearGradient></defs><path fill="url(#ai-icon-gradient-01)" fillRule="evenodd" d="m8.502 9.483 1.773 1.002-4.115 2.252-.024 6.5 4.143 2.342-1.703.921-4.04-2.283-.099-8.454 4.065-2.28Zm1.295 3.222-.065 4.615 5.737 3.27 4.143-2.341-.036 1.904-4.04 2.284-7.527-4.143.015-4.587 1.773-1.002ZM21.333 8.67 23 9.653v4.567l-7.428 4.31-4.05-2.306V14.22l4.05 2.363 5.761-3.23V8.67ZM8.428 5.47l4.05 2.306V9.78l-4.05-2.363-5.761 3.23v4.683L1 14.347V9.78l7.428-4.31Zm6.996-3.97 4.04 2.283.099 8.454-4.065 2.28-1.773-1.002 4.115-2.252.024-6.5-4.143-2.342 1.703-.921Zm-6.96.063 7.527 4.143-.015 4.587-1.773 1.002.065-4.615-5.737-3.27L4.388 5.75l.036-1.904 4.04-2.284Z"/></svg>

  // slack
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><defs><linearGradient id="ai-icon-gradient-01" x1=".023%" x2="99.977%" y1="61.101%" y2="38.551%"><stop offset="0%" stopColor="#E01A4F"/><stop offset="100%" stopColor="#006194"/></linearGradient><linearGradient id="ai-icon-gradient-02" x1=".023%" x2="99.977%" y1="61.101%" y2="38.551%"><stop offset="0%" stopColor="#E72D5F"/><stop offset="100%" stopColor="#0B9AE6"/></linearGradient></defs><path fill="url(#ai-icon-gradient-01)" fillRule="evenodd" d="M14.096 9.1h6.5a1 1 0 1 1 0 2h-6.5a1 1 0 1 1 0-2ZM3.6 13.1h6.5a1 1 0 1 1 0 2H3.6a1 1 0 1 1 0-2Zm10.496 0a1 1 0 0 1 1 1v6.5a1 1 0 1 1-2 0v-6.5a1 1 0 0 1 1-1Zm-4-10.5a1 1 0 0 1 1 1v6.5a1 1 0 1 1-2 0V3.6a1 1 0 0 1 1-1Zm4 2.5a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-4 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm8-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-12-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z" transform="rotate(58 12.098 12.1)"/></svg>

  // stars TODO:darkmode
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><defs><linearGradient id="ai-icon-gradient-01" x1="17.286%" x2="71.662%" y1="84.194%" y2="23.516%"><stop offset="0%" stopColor="#E01A4F"/><stop offset="100%" stopColor="#006194"/></linearGradient></defs><path fill="url(#ai-icon-gradient-01)" fillRule="evenodd" d="M14.032 5.55a.437.437 0 0 0-.3-.413l-1.085-.364a2.291 2.291 0 0 1-1.45-1.417l-.364-1.084a.437.437 0 0 0-.81 0l-.38 1.084a2.291 2.291 0 0 1-1.45 1.449l-1.085.364a.437.437 0 0 0 0 .81l1.085.364a2.291 2.291 0 0 1 1.45 1.448l.364 1.085a.437.437 0 0 0 .81 0l.364-1.085a2.291 2.291 0 0 1 1.45-1.448l1.085-.365a.437.437 0 0 0 .316-.428Zm7.62 5.608-1.222-.405a2.566 2.566 0 0 1-1.62-1.618l-.405-1.222a.486.486 0 0 0-.931 0l-.405 1.222a2.566 2.566 0 0 1-1.62 1.618l-1.222.405a.486.486 0 0 0 0 .93l1.222.405a2.566 2.566 0 0 1 1.62 1.619l.405 1.222a.486.486 0 0 0 .931 0l.405-1.222a2.566 2.566 0 0 1 1.62-1.619l1.223-.404a.486.486 0 0 0 0-.931Zm-9.531 4.945-1.62-.526a3.335 3.335 0 0 1-2.114-2.113l-.526-1.618a.64.64 0 0 0-1.207 0l-.526 1.618a3.335 3.335 0 0 1-2.081 2.113l-1.62.526a.64.64 0 0 0 0 1.205l1.62.526c1 .334 1.784 1.12 2.113 2.12l.527 1.62a.64.64 0 0 0 1.206 0l.527-1.62a3.335 3.335 0 0 1 2.114-2.111l1.62-.526a.64.64 0 0 0 0-1.206l-.033-.008Z"/></svg>

  // adinkra
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><defs><linearGradient id="ai-icon-gradient-01" x1="29.834%" x2="76.623%" y1="25.765%" y2="72.07%"><stop offset="0%" stopColor="#E01A4F"/><stop offset="100%" stopColor="#006194"/></linearGradient><linearGradient id="ai-icon-gradient-02" x1="29.834%" x2="76.623%" y1="25.765%" y2="72.07%"><stop offset="0%" stopColor="#E72D5F"/><stop offset="100%" stopColor="#0B9AE6"/></linearGradient></defs><path fill="url(#ai-icon-gradient-01)" fillRule="evenodd" d="M6.945 0C4.268.724 1.702 4.772 3.007 8.882c1.305 4.11 3.383 4.78 4.817 5.075.633.13.905-.208.816-1.015-1.718-.713-3.32-1.915-3.082-4.867.17-2.113.81-4.74 5.507-6.366 5.053-1.75 9.728.28 10.935 5.077-.716-2.706-4.722-5.299-8.789-3.98-4.067 1.319-4.73 3.418-5.022 4.868-.115.572.14.852.766.84.706-1.741 1.894-3.372 4.824-3.13 2.091.172 4.69.818 6.3 5.565 1.73 5.107-.278 9.83-5.024 11.051 2.677-.724 5.243-4.772 3.938-8.882-1.305-4.11-3.383-4.78-4.817-5.075-.633-.13-.905.208-.816 1.015 1.718.713 3.32 1.915 3.082 4.867-.17 2.113-.81 4.74-5.507 6.366-5.053 1.75-9.728-.28-10.935-5.077.716 2.706 4.722 5.299 8.789 3.98 4.067-1.319 4.73-3.418 5.022-4.868.115-.572-.14-.852-.766-.84-.706 1.741-1.894 3.372-4.824 3.13-2.091-.172-4.69-.818-6.3-5.565C.192 5.944 2.2 1.22 6.946 0Z" transform="translate(1 1)"/></svg>

  // sun 6
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><defs><linearGradient id="ai-icon-gradient-01" x1="30.85%" x2="75.282%" y1="25.765%" y2="72.07%"><stop offset="0%" stopColor="#E01A4F"/><stop offset="100%" stopColor="#006194"/></linearGradient><linearGradient id="ai-icon-gradient-02" x1="30.85%" x2="75.282%" y1="25.765%" y2="72.07%"><stop offset="0%" stopColor="#E72D5F"/><stop offset="100%" stopColor="#0B9AE6"/></linearGradient></defs><path fill="url(#ai-icon-gradient-01)" fillRule="evenodd" d="M13.507.785c-2.702-.732-5.749 1.282-7.734 4.72-1.984 3.437-.945 5.786.302 6.95.83.774 1.477.916 1.938.424l.016.004C6.909 11.456 6.073 9.69 6.437 8.11 7.153 5.003 9.286 2.383 13.02 2c3.734-.383 8.24.9 9.447 5.697-.717-2.705-3.985-4.337-7.954-4.337-3.97 0-5.484 2.074-5.868 3.736-.256 1.107-.056 1.737.6 1.89l.005.017c.676-1.684 1.788-3.291 3.34-3.766 3.047-.933 6.382-.396 8.58 2.646 2.156 2.982 3.296 7.408-.006 10.825l-.201.2.153-.16c1.816-2.006 1.554-5.548-.377-8.893-1.984-3.437-4.538-3.712-6.169-3.214-1.087.333-1.532.821-1.337 1.466l-.01.012c1.796-.256 3.743-.096 4.93 1.01 2.331 2.171 3.534 5.329 1.999 8.754-1.535 3.424-4.9 6.685-9.658 5.332 2.702.732 5.749-1.282 7.734-4.72 1.984-3.437.945-5.786-.302-6.95-.83-.774-1.477-.916-1.938-.424l-.016-.005c1.12 1.427 1.956 3.194 1.592 4.775-.716 3.106-2.849 5.726-6.582 6.109-3.734.383-8.24-.9-9.447-5.697.717 2.705 3.985 4.337 7.954 4.337 3.97 0 5.484-2.074 5.868-3.736.256-1.107.056-1.737-.6-1.89l-.005-.017c-.676 1.684-1.788 3.291-3.34 3.766-3.047.933-6.382.396-8.58-2.646C.674 13.135-.466 8.71 2.836 5.292l.193-.194-.145.153c-1.816 2.007-1.554 5.549.377 8.894 1.984 3.437 4.538 3.712 6.169 3.214 1.087-.333 1.532-.821 1.337-1.466l.013-.012c-1.797.256-3.745.097-4.932-1.01-2.332-2.171-3.535-5.329-2-8.754C5.384 2.693 8.75-.568 13.507.785Z" transform="matrix(0 1 1 0 0 0)"/></svg>

  // sun 5
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><defs><linearGradient id="ai-icon-gradient-01" x1="29.881%" x2="76.561%" y1="25.765%" y2="72.07%"><stop offset="0%" stopColor="#E01A4F"/><stop offset="100%" stopColor="#006194"/></linearGradient></defs><path fill="url(#ai-icon-gradient-01)" fillRule="evenodd" d="M.675 9.614C.911 6.825 3.846 4.65 7.755 3.96c3.91-.689 5.761 1.09 6.428 2.66.302.713.065 1.36-.713 1.944-1.292-1.456-3.066-2.734-4.82-2.581-3.175.276-6.066 2.023-6.965 5.667-.899 3.644-.255 8.285 4.327 10.148-2.58-1.086-3.74-4.55-3.188-8.48.552-3.931 2.817-5.142 4.516-5.291.77-.068 1.313.358 1.628 1.278-1.784.779-3.547 2.071-3.944 3.787-.719 3.105.05 6.395 3.238 8.376 3.187 1.98 7.8 2.802 10.988-.98-1.83 2.118-5.483 2.152-9.05.412-3.568-1.74-4.02-4.269-3.637-5.93.174-.754.747-1.14 1.719-1.154.189 1.938.874 4.014 2.382 4.922 2.731 1.642 6.098 1.928 8.967-.492 2.869-2.42 5.075-6.552 2.464-10.753 1.448 2.395.352 5.88-2.406 8.735-2.757 2.855-5.301 2.504-6.763 1.626-.663-.399-.852-1.063-.566-1.992 1.901.42 4.087.41 5.417-.744 2.406-2.09 3.718-5.203 2.303-8.68-1.414-3.476-4.663-6.852-9.465-5.666 2.725-.638 5.7 1.482 7.564 4.987 1.863 3.505.743 5.816-.544 6.935-.584.507-1.273.482-2.068-.077.985-1.679 1.652-3.761.965-5.382-1.244-2.934-3.8-5.145-7.543-4.873-3.743.271-7.958 2.317-8.314 7.25Z"/></svg>


});
AI.displayName = 'AI';


export const ArrowsUpDown: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" stroke={color} strokeWidth="2"><path d="M7 6v11"/><path strokeLinejoin="round" d="M12 9L7 4 2 9"/><path d="M17 7v11"/><path strokeLinejoin="round" d="M22 15l-5 5-5-5"/></g>
    </svg>;
});
ArrowsUpDown.displayName = 'ArrowsUpDown';



export const Bookmarked: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill="none" fillRule="evenodd"><path d="M19 21l-7-5-7 5V5c0-1.1.9-2 2-2h10a2 2 0 012 2v16z" fill={color} stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/></g></svg>
});
Bookmarked.displayName = 'Bookmarked';



export const Brush: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill="none" fillRule="evenodd" stroke={color} strokeWidth="2"><path d="M13.72 6.82c8.72-9.7 11.81-6.89 4.4 4-7.4 10.89-13.13 5.69-4.4-4Z"/><path d="M2 21.35c2.46-1.12 3.3-1.94 3.86-3.74.56-1.8 2.62-2.1 4.19-.83 1.57 1.28 1.06 2.36-.52 4.57-1.58 2.2-4.43 2.2-7.53 0Z"/></g></svg>
});
Brush.displayName = 'Brush';

export const Brush2: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill="none" fillRule="evenodd"><path d="M22.64.36c1.6 1.46.12 5.43-3.69 11.02a26.62 26.62 0 0 1-4.78 5.1.9.9 0 0 1-1.32-.19c-.3-.46-.19-1.08.26-1.4.41-.3.73-.55.95-.75a22.49 22.49 0 0 0 3.24-3.88l.4-.6c2.8-4.22 3.95-7.3 3.62-7.79 0 .02-.05.02-.18.06-.36.08-.86.33-1.46.73a30.38 30.38 0 0 0-5.22 4.83 20.55 20.55 0 0 0-3.49 5.03c-.13.3-.24.59-.34.87a.76.76 0 0 1-.93.48l-.23-.07a.87.87 0 0 1-.55-1.2c1.26-2.7 2.61-4.85 4.05-6.45l.51-.56C17.76.95 21.02-1.1 22.64.36Z" fill={color} fillRule="nonzero"/><path d="M2 21.35c2.46-1.12 3.3-1.94 3.86-3.74.56-1.8 2.62-2.1 4.19-.83 1.57 1.28 1.06 2.36-.52 4.57-1.58 2.2-4.43 2.2-7.53 0Z" stroke={color} strokeWidth="2"/><path d="M14 21h5a2 2 0 0 0 2-2v-6h0M12 3H5a2 2 0 0 0-2 2v11h0" stroke={color} strokeWidth="2" strokeLinecap="round"/></g></svg>
});
Brush.displayName = 'Brush2';




export const ChevronsUpDown: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><path d="M6 15l6 6 6-6M6 9l6-6 6 6"/></g>
    </svg>;
});
ChevronsUpDown.displayName = 'ChevronsUpDown';




export const CircleLoader: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}>
      <g transform="translate(2 2)" stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><circle opacity=".25" cx="10" cy="10" r="10"/><path d="M10 0a10 10 0 00-7.65 16.44"/></g>
    </svg>;
});
CircleLoader.displayName = 'CircleLoader';



export const CoinStack: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  // return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g transform="translate(3 2)" stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><ellipse cx="9" cy="3" rx="9" ry="3"></ellipse><path d="M18 12.33c0 1.66-4 3-9 3s-9-1.34-9-3M18 7.67c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M0 3v14c0 1.66 4 3 9 3s9-1.34 9-3V3"></path></g></svg>;
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill="none" fillRule="evenodd"><ellipse stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" cx="12" cy="5" rx="9" ry="3"/><path d="M21 14.3c0 1.7-4 3-9 3s-9-1.3-9-3M21 9.7c0 1.6-4 3-9 3s-9-1.4-9-3" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M3 5v14c0 1.7 4 3 9 3s9-1.3 9-3V5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><circle fill={color} cx="12" cy="10.3" r="1"/><circle fill={color} cx="7" cy="9.8" r="1"/><circle fill={color} cx="17" cy="9.8" r="1"/><circle fill={color} cx="14.5" cy="14.9" r="1"/><circle fill={color} cx="9.5" cy="14.9" r="1"/><circle fill={color} cx="4.5" cy="13.6" r="1"/><circle fill={color} cx="19.5" cy="13.6" r="1"/><circle fill={color} cx="12" cy="19.6" r="1"/><circle fill={color} cx="7" cy="19.1" r="1"/><circle fill={color} cx="17" cy="19.1" r="1"/></g></svg>

});
CoinStack.displayName = 'CoinStack';




export const Css: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fillRule="nonzero" stroke={color} strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21c-.96 0-2.63-.19-2.63-1.73v-4.11c0-2.46-1.17-2.95-2.37-3v-.09c1.2-.05 2.37-.54 2.37-3V4.74C6.37 3.19 8.04 3 9 3M15 21c.96 0 2.63-.19 2.63-1.73v-4.11c0-2.46 1.17-2.95 2.37-3v-.09c-1.2-.05-2.37-.54-2.37-3V4.74C17.63 3.19 15.96 3 15 3"/></g></svg>;
});
Css.displayName = 'Css';



export const EditStaple: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill={color} fillRule="evenodd"><path d="m19.7.3 4 4a1 1 0 0 1 0 1.4l-10 10a1 1 0 0 1-.7.3H9a1 1 0 0 1-1-1v-4a1 1 0 0 1 .3-.7l10-10a1 1 0 0 1 1.4 0ZM19 2.4l-9 9V14h2.59l9-9L19 2.41Z"/><path d="M9.34 3a1 1 0 1 1 0 2H7a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2.34a1 1 0 0 1 2 0V17a3 3 0 0 1-3 3h-1v1a3 3 0 0 1-2.82 3H3a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h1V6a3 3 0 0 1 2.82-3h2.52ZM4 9H3a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-1H7a3 3 0 0 1-3-2.82V9Z"/></g></svg>
});
EditStaple.displayName = 'EditStaple';



export const EyePlus: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill={color} fillRule="evenodd"><path d="M12 3h1c.5.1 1 .6 1 1.2 0 .5-.4.9-.9.9L12 5C9.3 5 6.8 6.4 4.6 8.7A18.5 18.5 0 0 0 2.1 12a17 17 0 0 0 2.5 3.3C6.8 17.6 9.3 19 12 19s5.2-1.4 7.4-3.7a18.5 18.5 0 0 0 2.5-3.3l-.1-.2-.4-.5-.2-.3a.6.6 0 0 1 .5-1h.1a2 2 0 0 1 1.8 1l.3.6c.1.2.1.6 0 .8l-.1.3-.2.2a19.1 19.1 0 0 1-2.7 3.8A12.1 12.1 0 0 1 12 21c-3.4 0-6.3-1.6-8.9-4.3-.8-1-1.6-2-2.2-3l-.2-.3-.2-.3a11 11 0 0 1-.4-.7 1 1 0 0 1 0-.8l.1-.3.2-.2A19.1 19.1 0 0 1 3 7.3C5.7 4.6 8.6 3 12 3Zm0 5a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/><path d="M20 0c.6 0 1 .4 1 1v2h2c.5 0 1 .4 1 .9V4c0 .6-.4 1-1 1h-2v2c0 .5-.4 1-.9 1H20a1 1 0 0 1-1-1V5h-2a1 1 0 0 1-1-.9V4c0-.6.4-1 1-1h2V1c0-.5.4-1 .9-1Z" fillRule="nonzero"/></g></svg>
});
EyePlus.displayName = 'EyePlus';




export const ExternalLinkMirrored: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><path d="M13 5a1 1 0 00-.1 2H19c.5 0 1 .4 1 .9V19c0 .5-.4 1-.9 1H8a1 1 0 01-1-.9V13a1 1 0 00-2-.1V19a3 3 0 002.8 3H19a3 3 0 003-2.8V8a3 3 0 00-2.8-3H13zM9 2H2.6l-.1.1-.1.1h-.1l-.1.2v.1l-.1.1V3H2V9a1 1 0 002 .1V5.4l9.3 9.3c.4.4 1 .4 1.3 0h.1c.4-.4.4-1 0-1.3v-.1L5.4 4H9c.5 0 1-.4 1-.9V3c0-.6-.4-1-1-1z" fill={color} fillRule="evenodd"/></svg>
});
ExternalLinkMirrored.displayName = 'ExternalLinkMirrored';




export const FilterX: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><path d="M22 3H2l8 9.46V19l4 2v-8.54zM7 17l-6 6M1 17l6 6"/></g></svg>;
});
FilterX.displayName = 'FilterX';


export const Indent: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke={color} strokeWidth="2"><path d="M8 6h9M3 5.077v13.846M8 12h13M8 18h9"/></g></svg>;
});
Indent.displayName = 'Indent';



export const Megaphone: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><path fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d="M13.2785282,17.5969971 L19.5896753,18.7876625 C20.9208609,19.0388049 22,18.1348714 22,16.7670753 L22,5.01050475 C22,3.64342284 20.9273387,2.76806771 19.602717,3.05418492 C19.602717,3.05418492 14.0887027,4.25977953 11.3382164,4.77868821 C8.8340163,5.25113236 3.83384034,6.01220385 3.83384034,6.01220385 C3.03264164,6.13586616 2.27844659,6.89154858 2.17573186,7.70240473 C2.17573186,7.70240473 2,8.25895103 2,10.7869684 C2,13.3149857 2.18736186,14.0736733 2.18736186,14.0736733 C2.29548811,14.8843127 3.02884888,15.6632854 3.82561619,15.813604 L7.24592984,16.4588828 C7.14743606,17.587561 6.73038272,18.8306485 5.6572502,19.927511 C5.17829248,20.5218753 4.94022428,20.852596 6.35248351,20.8722509 C9.09976464,20.7416127 11.785518,19.1333139 13.2785282,17.5969971 Z"/></svg>;
});
Megaphone.displayName = 'Megaphone';


export const MessageSquareOff: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" {...rest}><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke={color} strokeWidth="2"><path d="M18 18H7l-4 4V6a2 2 0 01.98-1.72M9 4h10a2 2 0 012 2v10M1 1l22 22"/></g></svg>;
});
Megaphone.displayName = 'Megaphone';


export const Pin: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24,...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round"><path d="M21.43 5.84l-2.08-.49-5.81 12.12 2.27 1.9-4.4-2.53L9.93 16l-1.49-.86L4 12.57l2.82 1.03 7.6-11.07L13 1z"/><path strokeLinecap="round" d="M5.81 22.99l4-6.89"/></g></svg>;
});
Pin.displayName = 'Pin';


export const NumberedList: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path strokeLinecap="round" d="M7.988 6.2h12.973M7.988 12.2h12.973M7.988 18.2h12.973"/>
      <path strokeWidth=".7" d="M2.24 5.256L3.6 4.235h.02V8.2M4.768 13.854H2.692c.741-.912 1.21-1.51 1.408-1.79.113-.14.416-.594.263-1.112a.925.925 0 00-.436-.57c-.19-.103-.433-.114-.65-.103-.43.02-.728.242-.977.827M2.278 19.19c.374.634.602.793 1.146.822.543.028.838-.178 1.03-.515.085-.151.17-.578-.037-.906-.194-.31-.714-.407-.807-.407l-.104-.002v-.021l.104-.001a.999.999 0 00.56-.236c.199-.19.284-.46.284-.704 0-.296-.16-.555-.382-.736-.22-.18-.656-.225-.957-.129-.3.097-.506.32-.669.684"/>
    </g>
  </svg>;
});
NumberedList.displayName = 'NumberedList';


export const Shield3: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><path d="M12.447214 22.894427c-.281528.140764-.6129.140764-.894428 0-.276878-.13844-.741708-.395756-1.332785-.765179-.980117-.612573-1.960061-1.323032-2.878506-2.126671C4.64576 17.643807 3 14.969449 3 12V4c0-.458868.312297-.85885.757464-.970143l8-2a1 1 0 0 1 .485072 0l8 2C20.687703 3.14115 21 3.541133 21 4v8c0 2.969449-1.64576 5.643808-4.341495 8.002577-.918445.803639-1.89839 1.514098-2.878506 2.126671-.591077.369423-1.055907.62674-1.332785.76518Z" fill={color} fillRule="evenodd"/></svg>
});
Shield3.displayName = 'Shield3';


export const StrikeThrough: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round" strokeLinecap="round">
      <path d="M21.5 12h-17M17.5 4.6H10a3.5 3.5 0 00-3.28 4.71M18.3 14.72A3.5 3.5 0 0115 19.4H6.5"/>
    </g>
  </svg>;
});
StrikeThrough.displayName = 'StrikeThrough';

export const Trophy: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><path d="M11 19v-3.1a5.37 5.37 0 0 1-2.188-1.037A4.48 4.48 0 0 1 7.4 12.95a4.854 4.854 0 0 1-3.138-1.637A4.812 4.812 0 0 1 3 8V7c0-.55.196-1.02.587-1.412A1.926 1.926 0 0 1 5 5h2c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 9 3h6c.55 0 1.02.196 1.413.587C16.803 3.98 17 4.45 17 5h2c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v1c0 1.267-.42 2.37-1.262 3.313A4.854 4.854 0 0 1 16.6 12.95a4.48 4.48 0 0 1-1.413 1.913A5.37 5.37 0 0 1 13 15.9V19h3c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 16 21H8a.968.968 0 0 1-.713-.288A.968.968 0 0 1 7 20c0-.283.096-.52.287-.712A.968.968 0 0 1 8 19h3Zm-4-8.2V7H5v1c0 .633.183 1.204.55 1.713.367.508.85.87 1.45 1.087Zm5 3.2c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 15 11V5H9v6c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 12 14Zm5-3.2c.6-.217 1.083-.58 1.45-1.088.367-.508.55-1.079.55-1.712V7h-2v3.8Z" fill={color} fillRule="nonzero"/></svg>
});
Trophy.displayName = 'Trophy';

export const Underline: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round" strokeLinecap="round">
    <path d="M18 20H6M17 4v8a5 5 0 01-10 0V4h0"/>
    </g>
  </svg>;
});
Underline.displayName = 'Underline';

export const Headline: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M7 4v16M16 12H8M17 4v16"/>
    </g>
  </svg>;
});
Headline.displayName = 'Headline';

export const Quote: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="1.7" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M4 12.4C4 11.1 5 10 6.4 10h1.2v4h2c.5 0 .9.4.9.9v4.8c0 .5-.4.9-.8.9H4.8a.8.8 0 01-.8-.9v-7.3zM20.3 12.1c0 1.4-1.1 2.5-2.5 2.5h-1.2v-4.1h-2a.8.8 0 01-.8-.8V4.8c0-.4.3-.8.8-.8h4.8c.5 0 .9.4.9.8v7.3z"/>
    </g>
  </svg>;
});
Quote.displayName = 'Quote';

export const Redo: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M20 5l-.1 7h-7M6.6 18.5a7 7 0 119.7-10"/>
    </g>
  </svg>;
});
Redo.displayName = 'Redo';

export const RssOff: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke={color} strokeWidth="2"><path d="M4 11c1.14 0 2.23.21 3.24.6M12.36 16.66c.41 1.03.64 2.16.64 3.34M4 4c3.15 0 6.08.9 8.56 2.48M17.63 11.62C19.13 14.06 20 16.92 20 20M22.5 1.5l-21 21"/></g></svg>
});
RssOff.displayName = 'RssOff';

export const Undo: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path d="M4 5l.1 7h7M17.4 18.5a7 7 0 10-9.7-10"/>
    </g>
  </svg>;
});
Undo.displayName = 'Undo';

export const PinOff: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" stroke={color} strokeWidth="2">
      <path d="M14.7 15.06l-1.16 2.4 2.27 1.92-4.4-2.54L9.93 16l-1.49-.86L4 12.57l2.82 1.03 2.65-3.86m2.79-4.05l2.17-3.16L12.99 1l8.44 4.84-2.08-.49-2.65 5.53M5.81 22.99l4-6.89"/>
      <g>
        <path d="M1 1l22 22"/>
      </g>
    </g>
  </svg>;
});
PinOff.displayName = 'PinOff';


export const Poll: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><path fill={color} fillRule="evenodd" d="M20 11H4v2h16v-2Zm2 3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4Zm-5 8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v4ZM12 6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4Zm3 15v-2H4v2h11ZM10 5V3H4v2h6Z"/></svg>;
});
Poll.displayName = 'Poll';


export const Puzzle: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><path d="M19.38 5.98a3.53 3.53 0 010 5l.8.8a2.1 2.1 0 010 2.97l-2.8 2.8-1.33-1.34a1.18 1.18 0 00-1.58-.08l-.08.08a1.18 1.18 0 00-.09 1.58l.09.09 1.33 1.33-2.47 2.46a2.1 2.1 0 01-2.97 0l-6.93-6.92a2.1 2.1 0 010-2.97l.8-.8a3.53 3.53 0 115-5l1.13-1.13a2.1 2.1 0 012.97 0l1.14 1.13a3.53 3.53 0 014.99 0zM17.96 7.4a1.53 1.53 0 00-2.06-.1l-.1.1-1.41 1.41-2.55-2.55a.1.1 0 00-.11-.02l-.04.02-2.55 2.55L7.73 7.4a1.53 1.53 0 00-2.26 2.06l.1.1 1.41 1.41-2.22 2.22a.1.1 0 00-.02.1l.02.04 6.93 6.93a.1.1 0 00.11.02l.04-.02 1.05-1.05-.07-.08-.07-.08a3.18 3.18 0 01.1-4.12l.12-.13.16-.15.08-.07a3.18 3.18 0 014.12.1l.05.04 1.39-1.39a.1.1 0 00.02-.1l-.02-.04-2.22-2.22 1.41-1.41c.6-.6.6-1.57 0-2.16z" fill={color} fillRule="nonzero"/></svg>
});
Puzzle.displayName = 'Puzzle';


export const Scoreboard: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><g fill="none" fillRule="evenodd"><circle fill="currentColor" cx="14" cy="9.5" r="1.5"/><circle fill="currentColor" cx="14" cy="14.5" r="1.5"/><path stroke="currentColor" strokeWidth="2" strokeLinejoin="round" d="M3.5 5h6v13h-6z"/><path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M17.5 5h3v13"/></g></svg>
});
Scoreboard.displayName = 'Scoreboard';


export const StarOff: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke={color} strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M10.4 5.23L12 2l3.09 6.26L22 9.27l-3.78 3.68m-.57 4.95l.53 3.12L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.16-.9M1 1l22 22"/>
    </g>
  </svg>;
});
StarOff.displayName = 'StarOff';



export const WebSearch: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><g fill={color} fillRule="nonzero"><path d="m7.6 18 2 1-.3 2.1c0 .5-.5.9-1 .9a1 1 0 0 1-1-1.1Zm-3.5-3.8c.8 1.6 2 2.9 3.5 3.8h-2l-.3 3.1c0 .5-.5.9-1 .9a1 1 0 0 1-1-1.1l.4-2.9H1.5a1 1 0 0 1-1-.9V17c0-.6.4-1 1-1h2.4ZM9.7 8c.6 0 1 .6 1 1.1l-.4 2.9h2.2c.5 0 1 .4 1 .9v.1c0 .6-.4 1-1 1h-1.9c-1-.5-1.8-1.4-2.2-2.4l.3-2.7c0-.5.5-.9 1-.9Zm-6.4 4c.1.7.4 1.4.7 2H1.5a1 1 0 0 1-1-.9V13c0-.6.4-1 1-1Z"/><path d="M12.8 1.5a8.2 8.2 0 0 1 6.4 13.3l4 4a1 1 0 0 1-1.4 1.4l-4-4a8.2 8.2 0 1 1-5-14.7Zm0 2a6.2 6.2 0 1 0 0 12.5 6.2 6.2 0 0 0 0-12.5Z"/></g></svg>
});
WebSearch.displayName = 'WebSearch';



export const Whistle: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><path d="m19.6 7.8.7-.4c.8-.3 1.7.1 2 1 .3.7-.2 1.6-1 2a6 6 0 0 1-8 7.7 6.2 6.2 0 0 1-3.4-3.3L4 17l-2.3-4 7.7-4.2c.2-.1.5.3 1 1.3l1-.7c0-.5-.6-1.2-.7-1.3l2.4-1.3c2.2-1 4.7-.6 6.5 1Z" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round"/></svg>
});
Whistle.displayName = 'Whistle';


export const XSocial: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}><g fill="none" fillRule="evenodd" strokeLinejoin="round" strokeWidth="2"><path stroke="currentColor" strokeLinecap="round" d="m10.41 13.22-8.12 8.97M20.58 1.98l-7.45 8.23"/><path stroke="currentColor" d="M2.59 1.98h4.69l14.21 20.21h-4.52z"/></g></svg>
});
XSocial.displayName = 'XSocial';


export const Dpa: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M12.83 9.7c1.51 0 2.75 1.34 2.75 3s-1.24 3-2.75 3a2.6 2.6 0 01-1.79-.71v4.41H9.3V9.94h1.75v.47a2.6 2.6 0 011.78-.71zM8.28 6v9.46H6.53v-.47a2.6 2.6 0 01-1.78.71c-1.52 0-2.75-1.34-2.75-3s1.23-3 2.75-3c.68 0 1.3.27 1.78.71V6h1.75zm10.67 3.7c.68 0 1.3.27 1.78.71v-.47h1.75v5.52h-1.75v-.47a2.6 2.6 0 01-1.78.71c-1.52 0-2.75-1.34-2.75-3s1.23-3 2.75-3zm-13.7 1.5c-.8 0-1.45.67-1.45 1.5s.65 1.5 1.45 1.5c.8 0 1.45-.67 1.45-1.5s-.65-1.5-1.45-1.5zm14.2 0c-.8 0-1.45.67-1.45 1.5s.65 1.5 1.45 1.5c.8 0 1.45-.67 1.45-1.5s-.65-1.5-1.45-1.5zm-7.12 0c-.8 0-1.45.67-1.45 1.5s.65 1.5 1.45 1.5c.8 0 1.45-.67 1.45-1.5s-.65-1.5-1.45-1.5z" fill="currentColor" fillRule="evenodd"/>
  </svg>;
});
Dpa.displayName = 'Dpa';



export const Getty: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g stroke="currentColor" fill="none" fillRule="evenodd">
      <path d="M17.76 6.1a33 33 0 00-.26 0s0 0 0 0a1.05 1.05 0 00-.99 1.35c.12.42.18.84.18 1.25 0 2.45-1.95 4.3-3.73 4.6-1.35.22-2.44.05-2.44.8 0 2.1 7.4-1.15 7.4 4.05 0 1.85-1.89 3.85-6.07 3.85C8.32 22 6 20.95 6 18.9a2.3 2.3 0 012.37-2.4v-.05c-.6-.37-1.94-.7-1.94-2.1 0-1.25 1.46-2.2 2.04-2.37-1.08-.88-1.94-1.88-1.94-3.45C6.53 6.25 8.29 4 11.7 4c.64 0 1.53.3 2.68.87h0a1 1 0 001.02-.07 4.03 4.03 0 012.35-.8 1.05 1.05 0 110 2.1z" strokeWidth="1.6"/>
      <path d="M12.5 19.83c1.63 0 2.18-.68 2.18-.98 0-.4-.75-1.02-3.2-1.02-1.39 0-1.8.65-1.8 1.02 0 .68.7.98 2.82.98zM9.4 8.45c0 1.2.94 2.15 2.27 2.15 1.19 0 2.15-.95 2.15-2.15s-.96-2.15-2.15-2.15c-1.33 0-2.27.95-2.27 2.15z" strokeWidth="1.6"/>
    </g>
  </svg>;
});
Getty.displayName = 'Getty';

export const Datawrapper: Icon = forwardRef<SVGSVGElement, IconProps>(({ color = 'currentColor', size = 24, ...rest }: IconProps, ref) => {
  return <svg ref={ref} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path d="M11.82 5c.86 0 1.65.15 2.36.44a5.24 5.24 0 013.04 3.17c.28.76.42 1.6.42 2.54v.64c0 .94-.14 1.78-.42 2.54a5.3 5.3 0 01-3.07 3.16c-.73.3-1.55.44-2.44.44H9a1 1 0 01-1-1V6a1 1 0 011-1zm0 1.81h-1.17a.4.4 0 00-.4.4v8.52c0 .22.18.4.4.4h1.06c.61 0 1.15-.1 1.6-.3.47-.2.85-.48 1.16-.85.3-.37.53-.83.69-1.37.15-.54.23-1.14.23-1.82v-.66c0-1.4-.3-2.47-.92-3.2-.6-.75-1.5-1.12-2.65-1.12z" fill="currentColor" fillRule="nonzero"/>
      <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M3 4v18.29h18.25"/>
    </g>
  </svg>;
});
Datawrapper.displayName = 'Datawrapper';
