import React, {HTMLAttributes, useState, useEffect} from "react";
import classNames from 'classnames'
import {useNiceDateTime} from "@webng/react-app-common";

interface DateTimeViewProp extends HTMLAttributes<HTMLSpanElement> {
  title?: string
  date?: string
  time?: string
  dateTime?: string,
  classNamesPrefix?: string
  children?: React.ReactNode,
  onlyDate?: boolean
}


export function DateTimeView({date, time, dateTime, title, className, classNamesPrefix, onlyDate, children, ...attributes}: DateTimeViewProp) {
  // we need to use state here because of SSR (title got not updated on the client after hydration)
  const [tmpTitle, setTmpTitle] = useState<undefined|string>();

  useEffect(() => {
    if (title) {
      setTmpTitle(title);
    }
  }, [title]);


  const timestampClassNames = classNames(className, {
    [`${classNamesPrefix}ts`]: classNamesPrefix,
    'timestamp': !classNamesPrefix
  })
  const timestampDateClassNames = classNamesPrefix ? `${classNamesPrefix}ts__date` : 'timestamp__date';
  const timestampTimeClassNames = classNamesPrefix ? `${classNamesPrefix}ts__time` : 'timestamp__time';
  const timestampTimeValClassNames = classNamesPrefix ? `${classNamesPrefix}ts__time__val` : 'timestamp__time__val';

  return <time suppressHydrationWarning={true} title={tmpTitle} dateTime={dateTime}
               className={timestampClassNames} {...attributes}>
    <span className={timestampDateClassNames} suppressHydrationWarning={true}>{date}</span>{' '}
    {!onlyDate && <span className={timestampTimeClassNames}>
      <span className={timestampTimeValClassNames} suppressHydrationWarning={true}>{time}</span>
      {children}
    </span>}
  </time>;
}


export interface NiceDateTimeProps extends HTMLAttributes<HTMLSpanElement> {
  ts?: string|number|"now",
  skipRelative?: boolean,
  classNamesPrefix?: string,
  children?: React.ReactNode,
  onlyDate?: boolean
}

export function NiceDateTime({ts, skipRelative, className, classNamesPrefix, onlyDate, children, ...attributes}: NiceDateTimeProps) {
  const {date, time, dateTime, isoString} = useNiceDateTime(ts, skipRelative);

  return <DateTimeView title={dateTime} dateTime={isoString} className={className} onlyDate={onlyDate}
                       classNamesPrefix={classNamesPrefix}
                       date={date} time={time} {...attributes}>
    {children}
  </DateTimeView>;
}

export interface NiceSportTimeProps extends HTMLAttributes<HTMLSpanElement> {

}
