import {ExternalUserProvider} from "@webng/comments";
import React, {FunctionComponent, ReactNode, useCallback, useContext, useMemo} from "react";
import {IMedia} from "@webng-types/write-model";
import {ConsentProvider, ProviderConstraints, ProviderConstraintValue} from "./webembed/ProviderConstraintAndConsent";
import {DefaultConsentProvider} from "./webembed/DefaultConsentProvider";
import {LayoutAuthorPosition} from "./LiveblogWrapper";

export interface MediaExtraComponentProps {
  media: IMedia|undefined
}

export const eventSharingTypes = ["off", "compact", "expanded"] as const
export type EventSharingType = typeof eventSharingTypes[number]

export const commentsModeValues = ['default', 'disabled']
export type CommentsMode = typeof commentsModeValues[number]

// Attention: l and t prefix have special analytics functinality (EventsHelper)
// EventType is also a enum in backend
export type EventType =
  "l_err" | // load error
  "l_ini" | // load reinit
  "l_mor" | // load with more
  "l_rfs" | // refresh load
  "t_ini" | // init
  "t_mor" | // load more click
  "t_upd" | // ticker updated (via click or auto)
  "t_tag" | // tag filter clicked
  "t_mil" | // milestone clicked
  "t_hlt" | // highlight clicked
  "a_shr" | // share
  "a_con" | // web embed consent toggled
  "a_clk" | // web embed clicked
  "c_opn" | // comments opened
  "c_mor" | // comments more click
  "a_wri" | // comment written
  "s_opn" | // slideshow opened
  "s_mvd" | // slideshow moved
  "v_ini" | // video initialized
  "s_wem"   // web embed clicked

export interface LiveblogEvent {
  t: EventType
  s?: number // status for load error
  e?: string // effected event
  m?: string // effected media
  url?: string // url of web embed
  ts?: number
}

export type TrackingFunction = (e: LiveblogEvent) => void

export interface LiveblogRenderContext {
  externalUserProvider?: ExternalUserProvider
  consentProvider: ConsentProvider
  MediaExtraComponent?: FunctionComponent<MediaExtraComponentProps>
  webEmbedProviderConstraints: ProviderConstraints
  webEmbedDefaultProviderConstraint: ProviderConstraintValue
  showEventSharing: EventSharingType
  commentsMode: CommentsMode
  layoutAuthorPosition: LayoutAuthorPosition
  uniqueId: string,
  useFunctionalCookies: boolean,
  trackingFunction: TrackingFunction
}

const DefaultLiveblogRenderContext: LiveblogRenderContext = {
  webEmbedDefaultProviderConstraint: 'consent',
  webEmbedProviderConstraints: {
    "Website": "simple"
  },
  consentProvider: new DefaultConsentProvider(),
  showEventSharing: "off",
  commentsMode: 'default',
  layoutAuthorPosition: "top",
  uniqueId: '0000',
  useFunctionalCookies: true,
  trackingFunction: () => {}
}

const Context = React.createContext<LiveblogRenderContext>(DefaultLiveblogRenderContext)

interface LiveblogRenderContextProviderProps {
  context: Partial<LiveblogRenderContext>
  children: ReactNode
}

export function LiveblogRenderContextProvider({children, context}: LiveblogRenderContextProviderProps) {
  const value = useMemo(() => {
    const ret = Object.assign({}, DefaultLiveblogRenderContext, context)
    if(!ret.uniqueId) {
      ret.uniqueId = (Math.floor(Math.random() * 0x19a0ff)).toString(36);
    }
    return ret
  }, [context])

  return <Context.Provider value={value}>
    {children}
  </Context.Provider>
}

export function useExternalUserProvider() {
  return useContext(Context).externalUserProvider
}

export function useMediaExtraComponent() {
  return useContext(Context).MediaExtraComponent
}


export function useWebEmbedProviderConstraint(providerName: string) {
  const ctx = useContext(Context)
  if(providerName === 'X' || providerName === 'Twitter') {
    return ctx.webEmbedProviderConstraints["X"] || ctx.webEmbedProviderConstraints["Twitter"] || ctx.webEmbedDefaultProviderConstraint
  } else {
    return ctx.webEmbedProviderConstraints[providerName] || ctx.webEmbedDefaultProviderConstraint
  }
}

export function useConsentProvider() {
  return useContext(Context).consentProvider
}

export function useEventSharingType() {
  return useContext(Context).showEventSharing
}

export function useLayoutAuthorPosition() {
  return useContext(Context).layoutAuthorPosition
}

export function useCommentsMode() {
  return useContext(Context).commentsMode
}

export function useUniqueId() {
  return useContext(Context).uniqueId
}

export function useUseFunctionalCookies() {
  return useContext(Context).useFunctionalCookies
}

export function useTrackingFunction() {
  const {trackingFunction} = useContext(Context)
  return useCallback((url: string|undefined) => {
    trackingFunction({t: "s_wem", url: url?.slice(0,256), ts: Date.now()})
  }, [trackingFunction])
}
