import {IEvent, IRichTextContentBlock} from "@webng-types/write-model";
import {parseHTML} from '@webng/isomorphic-dom'

export function getSportTiming(event: IEvent) {

  if(event.sport_event && (event.sport_event.time_of_play || event.sport_event.phase?.name)) {
    return {
      phase: event.sport_event.phase,
      timeOfPlay: event.sport_event.time_of_play
    }
  } else {
    return undefined
  }
}

function getTextContent(el: Element): string[] {
  let text: string[] = []
  el.childNodes.forEach((value, key) => {
    if(value.nodeType === 1) {
      text = text.concat(getTextContent(value as Element))
    } else if(value.nodeType === 3) {
      text.push((value as Text).wholeText)
    }
  })
  return text;
}

export function getHighlightPostText(event: IEvent){
  let richTextBlock = event.contents?.find(content => content._type === "Tik::Model::Content::RichTextContentBlock");
  if(richTextBlock){
    richTextBlock = richTextBlock as IRichTextContentBlock;

    const {element} = parseHTML(richTextBlock.text)

    const headlines = element.querySelectorAll("h1,h2,h3,h4,h5,h6")
    if(headlines.length > 0) {
      return getTextContent(headlines[0]).join(" ")
    } else {
      return getTextContent(element).join(" ")
    }
  } else {
    return ""
  }
}

export function getHeadlineFromEvent(event: IEvent){
  let richTextBlock = event.contents?.find(content => content._type === "Tik::Model::Content::RichTextContentBlock");
  if(richTextBlock){
    richTextBlock = richTextBlock as IRichTextContentBlock;

    const {element} = parseHTML(richTextBlock.text)

    const headlines = element.querySelectorAll("h1,h2,h3,h4,h5,h6")
    if(headlines.length > 0) {
      return getTextContent(headlines[0]).join(" ")
    } else {
      return null
    }
  } else {
    return null
  }
}
